import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2022-stories/more-stories"
import YouTube from 'react-youtube';

import Hero from '../../images/fall-winter-2022/unbreakable-zack-collie/hero.jpg';
import socialBanner from "../../images/fall-winter-2022/social-banners/unbreakable-zack-collie.jpg";


export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'The Unbreakable Zack Collie';
    var pageDesc = 'He broke his neck, but it didn’t break him. Counseling graduate student Zack Collie hopes to help other survivors of traumatic events find a path forward.';
    var slug = 'unbreakable-zack-collie';

    var blockquote = {
      border: 'none',
      border: 'none',
      float: 'left',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '0 20% 0 20%',
    }

    var blockquoteText = {
      fontFamily: 'kepler-std-display, serif',
      fontSize: '2.5rem',
      lineHeight: '1.25em'
    }

    var heroStyles = {
      backgroundColor: '#222',
      backgroundImage: `url(${Hero})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top center',
      backgroundSize: 'cover',
      color: 'white',
      height: '110vh'
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2022">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`fall-winter-2022 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">{pageTitle}</h1>
            <div className="wrap small"><p className="sub-heading animated fadeInDown delay-1s">He broke his neck, but it didn’t break him. Counseling graduate student Zack Collie hopes to help other survivors of traumatic events find a path forward.</p></div>
            <span className="animated fadeInDown delay-1s byline">By Zack Collie, Photo by Orange County Register/SCNG</span>
          </div>
          <div className="tint"></div>
        </div>
        <article className="wrap small">

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p><span className="intro-text">When I broke my neck at age 15,</span> I went from being an independent person to completely relying on others for almost everything. I couldn’t scratch an itch on my face, feed or dress myself. </p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>Since then, I have gained back a lot of my independence. I learned how to feed myself again. I moved into an apartment and live on my own with a caregiver. A few years ago, I learned how to drive with hand controls and got my license. I can drive my van completely on my own. Learning to drive has given me so much of my independence back.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>For years after my injury, I struggled trying to figure out what career I could have as a quadriplegic. A class at Cal State Fullerton changed my life.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <YouTube
            containerClassName="align-wide video"
            videoId="dzS0Nbl5V5E"
            opts={{
              height: '478',
              width: '850',
              playerVars: {
                rel: 0
              },
            }}
          />
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>Each week in “Character and Conflict,” we talked about a new topic such as death and loss, love and sex, gender, and finding meaning in life. We were told at the beginning that the class would change us, but we would have to be vulnerable to get the most out of it and we would have to work on ourselves. </p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>I experienced so much personal growth through that class. Afterward, I knew in my heart that the way I could give back and help others would be through counseling. There is no doubt in my mind this is what I am meant to do. </p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>I am currently in the last year of my master’s program studying counseling. After graduation, I plan to apply for a position as an associate therapist to complete the 3,000 hours required to become a licensed counselor. Seeing people come together and support me after I broke my neck, even complete strangers, made me realize that I want to do that for others.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>I did not receive counseling after my injury. When I was in rehab, I remember wishing I had someone to talk to besides my doctor, nurses or parents, but the rehab center didn’t offer counseling services. I started seeing a therapist on my own a few years ago and it has been incredibly helpful to have someone listen to what’s on my mind and what I’m going through.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <blockquote className="align-right align-outside" style={blockquote}>
            <p style={blockquoteText}>“My purpose in life is to motivate people to never give up.”</p>
          </blockquote>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>I think counseling is important for survivors of trauma or spinal cord injuries because it’s healthy to talk about the struggles we are going through. The worst thing we can do as human beings is to bottle emotions inside of us and bury them deep down. </p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>Since I have a disability, I see myself working with survivors of traumatic events, people with disabilities or individuals in correctional systems. I want to help normalize mental health and let people know it’s OK to talk about our feelings.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>
            Being paralyzed for 12 years, here are some of the lessons I’ve learned:<br /><br />
            
            I learned the importance of patience. I was not a patient person before my injury. <br /><br />
            
            I learned the value of family and friendships.<br /><br />
            
            I became closer to my faith. <br /><br />
            
            I learned the importance of suffering. Suffering is not for nothing: It makes us stronger.<br /><br />
            
            I learned that I don’t want people to feel sorry for me. <br /><br />
            
            I learned to choose happiness.<br /><br />
            
            I learned that my purpose in life is to motivate people to never give up.
          </p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>To someone who has gone through a traumatic injury or event: Life is not over. You can still live a happy and fulfilling life. I know it might not feel like it in the beginning. I never thought I would be where I am today after breaking my neck, but here I am. Keep moving forward, even if it is one step at a time. I was given a second chance at life, and I am going to make the absolute best out of it.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <hr />
          

          <p><em>Zack Collie, 27, broke his C4 vertebrae diving into a wave at Newport Beach in 2010, paralyzing all four of his limbs. Facing an uncertain future as a quadriplegic, he decided to attend college and completed his bachelor’s degree in human services at Cal State Fullerton in 2018. This winter, Collie will graduate with a master’s degree in counseling. His YouTube channel, <a href="https://www.youtube.com/channel/UC2BtIaeHx92IQhn-nw6QMqg" target="_blank" rel="noreferrer">“Quadlife With Zack,”</a> has 175,000 subscribers and aims to spread awareness about living with spinal cord injuries. The most-watched video, <a href="https://www.youtube.com/watch?v=U45DgjUuLrI" target="_blank" rel="noreferrer">“Quadriplegic Morning Routine and Workout,”</a> has 15 million views.</em></p>
          </ScrollAnimation>

          <br />
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <iframe src="https://anchor.fm/csufofficial/embed/episodes/Navigating-CSUF-with-Disability-Support-Services-e1pf900" height="102px" width="100%" frameborder="0" scrolling="no" style={{padding: '0 2%'}}></iframe>
          <p><center>The CSUF Podcast on Spotify: Learn about Disability Support Services with Jennifer Wheeler, disability management specialist.</center></p>
          </ScrollAnimation>

          
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p className="learn-more">Support the College of Health and Human Development<br /><a href="https://campaign.fullerton.edu/hhd" target="_blank">campaign.fullerton.edu/hhd</a></p>
          </ScrollAnimation>


        </article>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 